body{
    background-color: #e8e9d8;
}
#topNavbar{
    padding: 11px 0px;
    background-color: #003c71;
}
#logobsci{
    height: 47px;
}
#contentCenter{
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    */
    text-align: left;
}
.inputnoBackground{
    background-color: transparent;
}
